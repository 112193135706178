import React, { KeyboardEvent, MouseEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AppsIcon from '@material-ui/icons/Apps';
import DashboardIcon from '@material-ui/icons/Dashboard';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
});

export interface AppDrawerProps {
  onItemClick(event: MouseEvent): void;
  toggleDrawer(open: boolean): (event: KeyboardEvent | MouseEvent) => void;
  open: boolean;
}

export const AppDrawer: React.FC<AppDrawerProps> = props => {
  const { open, toggleDrawer, onItemClick } = props;
  const classes = useStyles();

  return (
    <Drawer open={open} onClose={toggleDrawer(false)}>
      <div
        className={classes.list}
        role='presentation'
        onClick={() => toggleDrawer(false)}
        onKeyDown={() => toggleDrawer(false)}
      >
        <List>
          <ListItem id='/' button key='dashboard' onClick={onItemClick}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary='Dashboard' />
          </ListItem>
          <ListItem id='/routes' button key='routes' onClick={onItemClick}>
            <ListItemIcon>
              <DashboardIcon style={{ opacity: 0.0 }} />
            </ListItemIcon>
            <ListItemText primary='Routes' />
          </ListItem>
          <ListItem id='/store' button key='store' onClick={onItemClick}>
            <ListItemIcon>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText primary='App Store' />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};
