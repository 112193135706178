import React, { useState, lazy, Suspense, useContext, KeyboardEvent, MouseEvent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import { deleteCookie, randomHSL } from './util';
import { UserContext } from './App';
import { AppDrawer } from './appDrawer';

const MobileSignInDialog = lazy(() => import('./signInDialog'));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      textTransform: 'capitalize',
    },
    colorPrimary: {
      background: randomHSL(),
      color: 'black',
    },
  })
);

export interface MainAppBarProps {
  onSignOut(): void;
  onSignIn(): void;
  onDrawerItemClick(route: string): void;
}

export const MainAppBar: React.FC<MainAppBarProps> = props => {
  const user = useContext(UserContext);
  const { onSignOut, onSignIn } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [signInOpen, setSignInOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  async function onSignOutClick() {
    setAnchorEl(null); // Close menu

    try {
      console.assert(user);

      // TODO: network failure
      //await (await import('./oidcClient')).startInteractionSession();

      // We fetch our mutated /oidc/session/end which returns an HTML form with
      // an xsrf token. Then we POST it back along with logout=yes. In response
      // our OpenID Provider will clear out its session, cookie, etc.
      const response = await fetch('/oidc/session/end', { credentials: 'include' });
      if (response.ok) {
        const formText = await response.text();

        // Let the DOM do the work of parsing and assembling a new form for us.
        const div = document.createElement('div');
        div.innerHTML = formText;
        const form = div.firstChild as HTMLFormElement;
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'logout';
        input.value = 'yes';
        form.appendChild(input);

        // Convert the form data into query param data because that's what /oidc/session/end likes.
        const data = new FormData(form);
        const params = new URLSearchParams();
        data.forEach((value, key) => params.append(key, value.toString()));

        const postResponse = await fetch('/oidc/session/end', {
          method: 'post',
          body: params,
          credentials: 'include',
        });

        if (postResponse.ok) {
          // Clear our id_token cookie.
          deleteCookie('id_token');

          // And access_token cookie.
          deleteCookie('access_token');

          // Let everybody know we aren't authenticated any more (update UI).
          onSignOut();
        } else {
          // TODO: something user friendly
          console.log(postResponse.status, postResponse.statusText);
          console.log(await postResponse.json());
        }
      } else {
        // TODO: something user friendly
        console.log(response.status, response.statusText);
        console.log(await response.json());
      }
    } catch (err) {
      // Not expecting anything to get here, since errors come as e.g. 400, 500.
      console.log(err);
    }
  }

  function onAccountClick() {
    setAnchorEl(null); // Close menu
  }

  function onSignInDone() {
    setSignInOpen(false);
    onSignIn();
  }

  const toggleDrawer = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  function onDrawerItemClick(event: MouseEvent<HTMLElement>) {
    toggleDrawer(false)(event);
    props.onDrawerItemClick(event.currentTarget.id);
  }

  return (
    <div className={classes.root}>
      <AppBar position='static' classes={{ colorPrimary: classes.colorPrimary }} elevation={0}>
        <AppDrawer open={drawerOpen} toggleDrawer={toggleDrawer} onItemClick={onDrawerItemClick} />
        <Toolbar variant='dense'>
          {user && (
            <IconButton
              onClick={() => setDrawerOpen(true)}
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='Menu'
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant='h6' className={classes.title}>
            {user ? `${user.cloudputer} Dashboard` : 'Cloudputer Central'}
          </Typography>
          {user ? (
            <>
              <div>
                <IconButton
                  aria-owns={menuOpen ? 'menu-appbar' : undefined}
                  aria-haspopup='true'
                  onClick={event => setAnchorEl(event.currentTarget)}
                  color='inherit'
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id='menu-appbar'
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={menuOpen}
                  onClose={() => setAnchorEl(null) /* Close menu */}
                >
                  <MenuItem onClick={onAccountClick} dense>
                    My Account
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={onSignOutClick} dense>
                    Sign Out
                  </MenuItem>
                </Menu>
              </div>
            </>
          ) : (
            <div>
              <Button onClick={() => setSignInOpen(true)} color='inherit'>
                <Typography variant='body2' className={classes.title}>
                  Sign In
                </Typography>
              </Button>
              <Suspense fallback={null}>
                <MobileSignInDialog open={signInOpen} onDone={onSignInDone} />
              </Suspense>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};
