import { useEffect } from 'react';

export type StringDictionary = { [key: string]: string };
export type UndefinedStringDictionary = { [key: string]: string | undefined };

/**
 * Like useEffect but works with async functions and makes sure that errors will be reported
 */
export function useAsyncEffect(effect: () => Promise<any>, deps?: []) {
  useEffect(() => {
    effect().catch(e => console.warn('useAsyncEffect error', e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function setCookie(name: string, value: string, days?: number): void {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function getCookie(name: string): string {
  const b = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)');
  return b ? b.pop()! : '';
}

export function deleteCookie(name: string): void {
  document.cookie = name + '=; Max-Age=-99999999;';
}

export function parseJwt(token: string): { [name: string]: string } {
  const base64Url = token.split('.')[1];
  const base64 = decodeURIComponent(
    atob(base64Url)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(base64);
}

export function randomHSL() {
  return `hsla(${~~(256 * Math.random())},60%,80%,0.8)`;
}
