import React, { useState, createContext, lazy, Suspense, useEffect } from 'react';
import { useRoutes, navigate } from 'hookrouter';
import CssBaseline from '@material-ui/core/CssBaseline';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { MainAppBar } from './appBar';
import { getCookie, parseJwt } from './util';
import { User } from './user';

const Dashboard = lazy(() => import('./dashboard'));
const AppStore = lazy(() => import('./appStore'));
const Routes = lazy(() => import('./routes'));

export const UserContext = createContext<User | undefined>(undefined);

export const App: React.FC = () => {
  const [user, setUser] = useState<User | undefined>(getUser());
  const [theme, setTheme] = useState(createMuiTheme({}));

  const routes = {
    '/': () => (
      <Suspense fallback={null}>
        <Dashboard />
      </Suspense>
    ),
    '/routes': () => (
      <Suspense fallback={null}>
        {user ? (
          <Routes />
        ) : (
          <div
            style={{ display: 'flex', justifyContent: 'center', margin: '16px', fontSize: 'large' }}
          >
            Sign in!
          </div>
        )}
      </Suspense>
    ),
    '/store': () => (
      <Suspense fallback={null}>
        <AppStore />
      </Suspense>
    ),
  };

  const route = useRoutes(routes);

  useEffect(() => {
    document.title = user ? user.cloudputer : 'Cloudputer';
    setTheme(
      createMuiTheme(
        user
          ? {
              palette: {
                primary: { main: '#5D4037' },
                secondary: { main: '#558B2F' },
              },
            }
          : {}
      )
    );
  }, [user]);

  function getUser(): User | undefined {
    const id_token = getCookie('id_token');
    return id_token ? ((parseJwt(id_token) as any) as User) : undefined;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UserContext.Provider value={user}>
        <MainAppBar
          onSignOut={() => {
            setUser(undefined);
            navigate('/');
          }}
          onSignIn={() => setUser(getUser())}
          onDrawerItemClick={route => navigate(route)}
        />
        {route}
      </UserContext.Provider>
    </ThemeProvider>
  );
};
